export const CreateAccountConstants = {
  URL_PARAMS: {
    INVITE_CODE: "invite_code",
    REFERRAL_CODE: "referral_code",
    EMAIL: "email"
  },
  CREATE_ACCOUNT_FIELDS: {
    INVITE_CODE_FIELD: {
      FIELD_NAME: "inviteCode",
      FIELD_TITLE: "Invite Code"
    },
    REFERRAL_CODE_FIELD: {
      FIELD_NAME: "referralCode",
      FIELD_TITLE: "Referral Code"
    }
  }
};
